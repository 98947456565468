import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, PanelHeader, PanelHeaderBack, Button, FixedLayout, Div, Radio } from '@vkontakte/vkui';
import './index.scss';

import { calculateQuiz, addQuizPoints } from '../../../store/quizes/actions';

const QuizTask = ({ id, changePanel }) => {
    const dispatch = useDispatch();
    const onClose = () => changePanel("quizes");

    const quiz = useSelector((state) => state.quizes.selected);
    const tasks = useSelector((state) => state.quizes.selected.tasks);
    const [activeTask, setActiveTask] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);

    const addPoints = useCallback((points) => dispatch(addQuizPoints(points)), [dispatch]);

    useEffect(() => {
        if (activeTask > tasks.length - 1) {
            dispatch(calculateQuiz());
            changePanel("quiz-final");
        }
    }, [activeTask, tasks, dispatch]);

    const hideSubmitButton = tasks[activeTask] && tasks[activeTask].options.map((x) => !!x.image).indexOf(true) !== -1;

    return (
        <Panel id={id} theme="white">
            <PanelHeader
                left={<PanelHeaderBack onClick={onClose} />}
            >
                {quiz.name}
            </PanelHeader>
            <Div>
                {tasks[activeTask] && tasks[activeTask].image && (
                    <div
                        className="quiz-task__image"
                        style={{
                            backgroundImage: `url(${tasks[activeTask].image})`
                        }}
                    />
                )}
                <div className="quiz-task__text">
                    {tasks[activeTask] && tasks[activeTask].text}
                </div>
                <div className="quiz-task__radio">
                    {tasks[activeTask] && tasks[activeTask].options.map((option) => {
                        if (option.image) {
                            return (
                                <div
                                    key={option.text}
                                    className="quiz-task__image"
                                    style={{ backgroundImage: `url(${option.image})` }}
                                    onClick={() => {
                                        addPoints(option.points);
                                        setSelectedOption(null);
                                        setActiveTask((p) => p + 1);
                                    }}
                                >
                                    <div className="quiz-task__cover">
                                        <span>{option.text}</span>
                                    </div>
                                </div>
                            );
                        }

                        return (
                            <Radio
                                key={option.text}
                                name={option.text}
                                checked={selectedOption === option}
                                onChange={() => setSelectedOption(option)}
                            >
                                {option.text}
                            </Radio>
                        )
                    })}
                </div>
            </Div>
            {!hideSubmitButton && (
                <FixedLayout vertical="bottom">
                    <Div>
                        <Button
                            size="xl"
                            disabled={selectedOption === null}
                            onClick={() => {
                                addPoints(selectedOption.points);
                                setSelectedOption(null);
                                setActiveTask((p) => p + 1);
                            }}
                        >
                            Далее
                        </Button>
                    </Div>
                </FixedLayout>
            )}
        </Panel>
    );
};

export default QuizTask;
