import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, PanelHeader, PanelHeaderBack, Select, Button } from '@vkontakte/vkui';
import './index.scss';

import { openView } from '../../../store/navigator/actions';
import { resultCalculator } from '../../../store/calculator/actions';

import IconCalcTop from '../../../assets/calc_top.svg';

const CalculatorIntro = ({ id, changePanel }) => {
    const dispatch = useDispatch();
    const period = useSelector((state) => state.calculator.period);
    const compute = useCallback((year) => dispatch(resultCalculator(year)), [dispatch]);
    const onClose = useCallback(() => dispatch(openView("home", "home")), [dispatch]);

    const [year, setYear] = useState(null);
    const renderYears = () => {
        const labels = [];
        for (let i = period[1]; i > period[0] - 1; i--) {
            labels.push(
                <option key={i} value={i}>{i}</option>
            );
        }
        return labels;
    };

    return (
        <Panel id={id}>
            <PanelHeader
                left={<PanelHeaderBack onClick={onClose} />}
            >
                Калькулятор
            </PanelHeader>
            <div className="calculator-intro">
                <div className="calculator-intro__icon">
                    <img src={IconCalcTop} alt="" />
                </div>
                <div className="calculator-intro__text">
                    Выберите год, когда примерно начали болеть за нашу сборную и узнайте, что еще произошло в том году!
                </div>
                <div className="calculator-intro__form">
                    <Select placeholder="Выберите год" onChange={(e) => setYear(e.target.value)}>
                        {renderYears()}
                    </Select>
                    <Button
                        disabled={year === null}
                        onClick={() => {
                            compute(year);
                            changePanel("calculator-result");
                        }}
                    >
                        Узнать
                    </Button>
                </div>
            </div>
        </Panel>
    );
};

export default CalculatorIntro;
