import React from 'react';
import { useSelector } from 'react-redux';
import { Panel, PanelHeader, PanelHeaderBack, Div } from '@vkontakte/vkui';
import './index.scss';

import VideoCard from '../../../components/VideoCard';
import GuestCard from '../../../components/GuestCard';

import Icon20PlaceOutline from '@vkontakte/icons/dist/20/place_outline';
import Icon20RecentOutline from '@vkontakte/icons/dist/20/recent_outline';

const CourseInfo = ({ id, changePanel }) => {
    const goBack = () => changePanel("courses");
    const course = useSelector((state) => state.courses.selected);

    return (
        <Panel id={id}>
            <PanelHeader
                left={<PanelHeaderBack onClick={goBack} />}
            >
                Лекция #{course && course.id}
            </PanelHeader>
            <Div>
                <VideoCard
                    live={Boolean(course && course.live)}
                    video_url={course && course.video_url}
                    cover_url={course && course.cover_url}
                />
                <div className="course-info">
                    <div className="course-info__name">{course && course.name}</div>
                    <div className="course-info__description">
                        {course && course.description}
                    </div>
                    <div className="course-info__details">
                        <div className="course-card__detail">
                            <Icon20PlaceOutline />
                            <div className="course-card__text">{course && course.place}</div>
                        </div>
                        <div className="course-card__detail">
                            <Icon20RecentOutline />
                            <div className="course-card__text">{course && course.time}</div>
                        </div>
                    </div>
                </div>
                {course && course.guests && course.guests.length > 0 && (
                    <div className="block">
                        <div className="block__title">Приглашённые гости</div>
                        <div className="block__content">
                            {course.guests.map((guest) => (
                                <GuestCard
                                    key={guest.name}
                                    avatar_url={guest.avatar_url}
                                    name={guest.name}
                                    description={guest.description}
                                    link={guest.link}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </Div>
        </Panel>
    );
};

export default CourseInfo;
