import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@vkontakte/vkui';
import FireEvent from '../../utils/FireEvent';
import './index.scss';

import Icon20PlaceOutline from '@vkontakte/icons/dist/20/place_outline';
import Icon20RecentOutline from '@vkontakte/icons/dist/20/recent_outline';
import Icon24Chevron from '@vkontakte/icons/dist/24/chevron';
import Icon28ViewOutline from '@vkontakte/icons/dist/28/view_outline';
import Icon24RecentOutline from '@vkontakte/icons/dist/24/recent_outline';

const CourseCard = ({ name, place, time, live, cover_url, video_url, onOpen }) => {
    return (
        <Card size="l" mode="shadow" className="course-card">
            <div className="course-card__cover">
                <div
                    className="course-card__image"
                    style={{
                        backgroundImage: `url("${cover_url}")`
                    }}
                />
                <div
                    className={`course-card__button ${live && "course-card__button-live"}`}
                    onClick={video_url ? () => FireEvent(video_url) : null}
                >
                    {video_url ? <Icon28ViewOutline width="24" height="24" /> : <Icon24RecentOutline />}
                    <span>{video_url ? "Смотреть" : "Скоро"}</span>
                </div>
            </div>
            <div className="course-card__info" onClick={onOpen}>
                <div className="course-card__name">
                    <span>{name}</span>
                    <Icon24Chevron />
                </div>
                <div className="course-card__detail">
                    <Icon20PlaceOutline />
                    <div className="course-card__text">{place}</div>
                </div>
                <div className="course-card__detail">
                    <Icon20RecentOutline />
                    <div className="course-card__text">{time}</div>
                </div>
            </div>
        </Card>
    );
};

CourseCard.propTypes = {
    name: PropTypes.string,
    place: PropTypes.string,
    time: PropTypes.string,
    video_url: PropTypes.string,
    cover_url: PropTypes.string,
    live: PropTypes.bool,
    onOpen: PropTypes.func,
};

export default CourseCard;
