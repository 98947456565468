import React from 'react';
import { useSelector } from 'react-redux';
import { Root } from '@vkontakte/vkui';
import './App.scss';

import Page from './utils/Page';

import Home from './containers/Home/Home';
import Courses from './containers/Courses/Courses';
import CourseInfo from './containers/Courses/CourseInfo';
import Quizes from './containers/Quizes/Quizes';
import QuizTask from './containers/Quizes/QuizTask';
import QuizFinal from './containers/Quizes/QuizFinal';
import CalculatorIntro from './containers/Calculator/CalculatorIntro';
import CalculatorResult from './containers/Calculator/CalculatorResult';

const App = () => {
	const activeView = useSelector((state) => state.navigator.view);
	return (
		<Root activeView={activeView}>
			<Page id="home" initialPanel="home">
				<Home id="home" />
			</Page>
			<Page id="courses" initialPanel="courses">
				<Courses id="courses" />
				<CourseInfo id="course-info" />
			</Page>
			<Page id="quizes" initialPanel="quizes">
				<Quizes id="quizes" />
				<QuizTask id="quiz-task" />
				<QuizFinal id="quiz-final" />
			</Page>
			<Page id="calculator" initialPanel="calculator-intro">
				<CalculatorIntro id="calculator-intro" />
				<CalculatorResult id="calculator-result" />
			</Page>
		</Root>
	);
};

export default App;

