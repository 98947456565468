import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@vkontakte/vkui';
import './index.scss';

import Icon56LockOutline from '@vkontakte/icons/dist/56/lock_outline';

const QuizCard = ({ name, number, locked, color, onOpen }) => {
    return (
        <Card size="m" mode="shadow" style={number % 2 !== 0 ? { marginLeft: 0 } : {}}>
            <div className="quiz-card"  onClick={onOpen}>
                {!locked && <div className="quiz-card__name">{name}</div>}
                <div
                    className="quiz-card__number"
                    style={{ color }}
                >
                    {number}
                </div>
                {locked && (
                    <div className="quiz-card__locked">
                        <Icon56LockOutline fill="var(--text_secondary)" />
                    </div>
                )}
            </div>
        </Card>
    );
};

QuizCard.propTypes = {
    name: PropTypes.string,
    number: PropTypes.number,
    locked: PropTypes.bool,
    color: PropTypes.string,
    onOpen: PropTypes.func,
};

export default QuizCard;
