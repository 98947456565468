import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Panel, PanelHeader, PanelHeaderBack, Group, CardGrid, Alert} from '@vkontakte/vkui';
import './index.scss';

import QuizCard from '../../../components/QuizCard';

import { openView } from '../../../store/navigator/actions';
import { openQuiz } from '../../../store/quizes/actions';

const Quizes = ({ id, changePanel, setLoading, setPopout }) => {
    const dispatch = useDispatch();
    const goBack = useCallback(() => dispatch(openView('home', 'home')), [dispatch]);

    const isQuizesLoad = useSelector((state) => state.quizes.loaded);
    const quizes = useSelector((state) => state.quizes.list);

    useEffect(() => setLoading(!isQuizesLoad), [isQuizesLoad]);

    const onOpenQuiz = (quiz) => {
        if (quiz.locked) {
            return setPopout(
                <Alert
                    actionsLayout="vertical"
                    actions={[{
                        title: 'Закрыть',
                        autoclose: true,
                        mode: 'cancel'
                    }]}
                    onClose={() => setPopout(null)}
                >
                    <h2>Викторина недоступна</h2>
                    <p>Мы пришлём уведомление, когда она откроется.</p>
                </Alert>
            );
        }

        dispatch(openQuiz(quiz.id));
        changePanel("quiz-task");
    };

    return (
        <Panel id={id}>
            <PanelHeader
                left={<PanelHeaderBack onClick={goBack} />}
            >
                Викторины
            </PanelHeader>
            <Group separator={false}>
                <CardGrid>
                    {quizes.map((quiz) => (
                        <QuizCard
                            key={quiz.id}
                            name={quiz.name}
                            number={quiz.number}
                            color={quiz.color}
                            locked={quiz.locked}
                            onOpen={() => onOpenQuiz(quiz)}
                        />
                    ))}
                </CardGrid>
            </Group>
        </Panel>
    );
};

export default Quizes;
