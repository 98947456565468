import React, { useCallback } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Panel, PanelHeader, CardGrid, Card, Group, Alert } from '@vkontakte/vkui';
import FireEvent from '../../utils/FireEvent';

import SectionCard from '../../components/SectionCard';

import { openView } from '../../store/navigator/actions';

import IconCourses from '../../assets/lectures.svg';
import IconQuizes from '../../assets/games.svg';
import IconCalc from '../../assets/calc.svg';
import IconPoll from '../../assets/poll.svg';
import IconMask from '../../assets/mask.svg';

const Home = ({ id, setPopout }) => {
    const dispatch = useDispatch();
    const vote_link = useSelector((state) => state.vote.link);
    const openSection = useCallback((name) => dispatch(openView(name, name)), [dispatch]);

    const openPoll = () => {
        if (vote_link === null) {
            return setPopout(
                <Alert
                    actionsLayout="vertical"
                    actions={[{
                        title: 'Закрыть',
                        autoclose: true,
                        mode: 'cancel'
                    }]}
                    onClose={() => setPopout(null)}
                >
                    <h2>Опрос ещё не начался</h2>
                    <p>Загляните сюда позже</p>
                </Alert>
            );
        }

        FireEvent(vote_link);
    };

    return (
        <Panel id={id}>
            <PanelHeader>Академия футбола</PanelHeader>
            <Group separator={false}>
                <CardGrid>
                    <Card size="l" mode="shadow">
                        <SectionCard
                            icon={IconCourses}
                            name="Лекции"
                            description="Смотрите. Развивайтесь. Любите футбол"
                            color="#00975F"
                            onClick={() => openSection("courses")}
                        />
                    </Card>
                    <Card size="l" mode="shadow">
                        <SectionCard
                            icon={IconQuizes}
                            name="Викторины"
                            description="Разгадывайте задачки на тему футбола"
                            color="#00975F"
                            onClick={() => openSection("quizes")}
                        />
                    </Card>
                    <Card size="l" mode="shadow">
                        <SectionCard
                            icon={IconCalc}
                            name="Калькулятор болельщика"
                            description="111"
                            color="#00975F"
                            onClick={() => openSection("calculator")}
                        />
                    </Card>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <Card mode="shadow">
                            <SectionCard
                                icon={IconMask}
                                name="Маска"
                                color="#00975F"
                                onClick={() => FireEvent("https://vk.com/mask-191976220_17440")}
                            />
                        </Card>
                        <Card mode="shadow">
                            <SectionCard
                                icon={IconPoll}
                                name="Опрос"
                                color="#00975F"
                                onClick={openPoll}
                            />
                        </Card>
                    </div>
                </CardGrid>
            </Group>
        </Panel>
    );
};

export default Home;
