import * as types from './actionTypes';

export const loadQuizes = (quizes = []) => {
    return {
        type: types.QUIZES_LOAD,
        quizes,
    };
};

export const openQuiz = (quiz_id = null) => {
    return {
        type: types.QUIZES_OPEN,
        id: quiz_id,
    };
};

export const addQuizPoints = (points = 0) => {
    return {
        type: types.QUIZES_ADD_POINTS,
        points,
    };
};

export const calculateQuiz = () => {
    return {
        type: types.QUIZES_CALCULATE
    };
};
