import * as types from './actionTypes';

const initialState = {
    loaded: false,
    list: [],
    selected: null,
    points: 0,
    result: null,
};

const dividePoints = (points, max_points) => {
    let result = [];
    while (points > max_points) {
        result.push(points % max_points);
        points = Math.floor(points / max_points);
    }
    return [...result, points];
};

const calculate = (items = [], points) => {
    for (let i = 0; i < items.length; i++) {
        if (points <= items[i].points) {
            return items[i];
        }
    }

    return items[items.length - 1];
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.QUIZES_LOAD: {
            const { quizes } = action;
            return {
                ...state,
                loaded: true,
                list: quizes,
            };
        }

        case types.QUIZES_OPEN: {
            const { id } = action;
            return {
                ...state,
                selected: state.list.find((x) => x.id === id),
                points: 0,
                result: null,
            };
        }

        case types.QUIZES_ADD_POINTS: {
            const { points } = action;
            return {
                ...state,
                points: state.points + points,
            };
        }

        case types.QUIZES_CALCULATE: {
            if (state.selected.special) {
                const divided_points = dividePoints(state.points, state.selected.max_points);
                const max_index = divided_points.indexOf(Math.max(...divided_points));
                const role = state.selected.roles[max_index];
                const result_array = state.selected.results[max_index];
                const result = result_array[Math.floor(Math.random() * result_array.length)];
                return {
                    ...state,
                    result: {
                        points: divided_points[max_index],
                        total: state.selected.tasks.length,
                        special: true,
                        text: `Я - ${role} ${result}`,
                        image: state.selected.story_image,
                    }
                };
            }

            return {
                ...state,
                result: {
                    ...calculate(state.selected.results, state.points),
                    points: state.points,
                    total: state.selected.tasks.reduce((a, task) => {
                        return a + Math.max(...task.options.map((x) => x.points));
                    }, 0),
                    image: state.selected.story_image,
                }
            };
        }

        default: return state;
    }
};
