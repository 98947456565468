import React, { useState } from 'react';
import { View, Spinner } from '@vkontakte/vkui';

const Page = ({ id, initialPanel, header = true, children }) => {
    const [activePanel, setActivePanel] = useState(initialPanel);
    const [popout, setPopout] = useState(null);
    return (
        <View id={id} activePanel={activePanel} header={header} popout={popout}>
            {React.Children.map(children, (child) => (
                React.cloneElement(child, {
                    ...child.props,
                    changePanel: setActivePanel,
                    setLoading: (state) => setPopout(state ? <Spinner /> : null),
                    setPopout: (state) => setPopout(state),
                })
            ))}
        </View>
    );
};

export default Page;
