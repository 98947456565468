import * as types from './actionTypes';

export const openPanel = (panel = null) => {
    return {
        type: types.NAVIGATOR_OPEN_PANEL,
        panel,
    };
};

export const openView = (view = null, panel = null) => {
    return {
        type: types.NAVIGATOR_OPEN_VIEW,
        view,
        panel,
    };
};
