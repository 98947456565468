const ENDPOINT = '/data';

export const getCourses = () => new Promise(async (resolve) => {
    fetch(ENDPOINT + '/courses.json')
        .then(async (response) => {
            const data = await response.json();
            resolve(data.items);
        })
        .catch((e) => {
            console.log(e);
            return resolve([]);
        });
});

export const getQuizes = () => new Promise(async (resolve) => {
    fetch(ENDPOINT + '/quizes.json')
        .then(async (response) => {
            const data = await response.json();
            resolve(data.items);
        })
        .catch((e) => {
            console.log(e);
            return resolve([]);
        });
});

export const getCalculator = () => new Promise((resolve) => {
    fetch(ENDPOINT + '/calculator.json')
        .then(async (response) => {
            const data = await response.json();
            resolve(data);
        })
        .catch((e) => {
            console.log(e);
            return resolve({
                "disabled": true,
                "period": [2021, 2020],
                "covers": [],
                "facts": {}
            });
        });
});

export const getVote = () => new Promise((resolve) => {
    fetch(ENDPOINT + '/vote.json')
        .then(async (response) => {
            const data = await response.json();
            resolve(data.link);
        })
        .catch((e) => {
            console.log(e);
            return resolve(null);
        });
});
