import React from 'react';
import PropTypes from 'prop-types';
import FireEvent from '../../utils/FireEvent';
import './index.scss';

import Icon28ViewOutline from '@vkontakte/icons/dist/28/view_outline';
import Icon24RecentOutline from '@vkontakte/icons/dist/24/recent_outline';

const VideoCard = ({ cover_url, video_url, live }) => {
    return (
        <div className="video-card">
            <div
                className="video-card__image"
                style={{
                    backgroundImage: `url(${cover_url})`
                }}
            />
            <div
                className={`video-card__button ${live && "video-card__button-live"}`}
                onClick={video_url ? () => FireEvent(video_url) : null}
            >
                {video_url ? <Icon28ViewOutline width="24" height="24" /> : <Icon24RecentOutline />}
                <span>{video_url ? "Смотреть" : "Скоро"}</span>
            </div>
        </div>
    );
};

VideoCard.propTypes = {
    cover_url: PropTypes.string,
    video_url: PropTypes.string,
    live: PropTypes.bool,
};

export default VideoCard;
