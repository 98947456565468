import * as types from './actionTypes';

export const loadCalculator = (covers = [], period = [0, 0], facts = {}) => {
    return {
        type: types.CALCULATOR_LOAD,
        covers,
        period,
        facts,
    };
};

export const resultCalculator = (year) => {
    return {
        type: types.CALCULATOR_RESULT,
        year
    };
};
