import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Panel, PanelHeader, CardGrid, PanelHeaderBack, Group } from '@vkontakte/vkui';

import CourseCard from '../../components/CourseCard';

import { openView } from '../../store/navigator/actions';
import { openCourse } from '../../store/courses/actions';

const Courses = ({ id, changePanel, setLoading }) => {
    const dispatch = useDispatch();
    const goBack = useCallback(() => dispatch(openView("home", "home")), [dispatch]);

    const isCoursesLoad = useSelector((state) => state.courses.loaded);
    const courses = useSelector((state) => state.courses.list);

    useEffect(() => setLoading(!isCoursesLoad), [isCoursesLoad]);

    return (
        <Panel id={id}>
            <PanelHeader
                left={<PanelHeaderBack onClick={goBack} />}
            >
                Лекции
            </PanelHeader>
            <Group separator={false}>
                <CardGrid>
                    {[...courses].reverse().map((course) => (
                        <CourseCard
                            key={course.id}
                            live={Boolean(course.live)}
                            video_url={course.video_url}
                            cover_url={course.cover_url}
                            name={course.name}
                            place={course.place}
                            time={course.time}
                            onOpen={() => {
                                dispatch(openCourse(course.id));
                                changePanel("course-info");
                            }}
                        />
                    ))}
                </CardGrid>
            </Group>
        </Panel>
    );
};

export default Courses;
