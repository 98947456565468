import React from 'react';
import PropTypes from 'prop-types';
import FireEvent from '../../utils/FireEvent';
import './index.scss';

import Icon24Chevron from '@vkontakte/icons/dist/24/chevron';

const GuestCard = ({ avatar_url, name, description, link }) => {
    return (
        <div className="guest-card" onClick={!link ? null : () => FireEvent(link)}>
            <div
                className="guest-card__avatar"
                style={{
                    backgroundImage: `url(${avatar_url})`
                }}
            />
            <div className="guest-card__info">
                <div className="guest-card__name">{name}</div>
                <div className="guest-card__description">{description}</div>
            </div>
            {link && <Icon24Chevron className="guest-card__chevron" />}
        </div>
    );
};

GuestCard.propTypes = {
    avatar_url: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
};

export default GuestCard;
