import React, { useState } from 'react';
import VKStories from 'vk-stories';
import { useSelector } from 'react-redux';
import { Panel, PanelHeader, PanelHeaderBack, Div, FixedLayout, Button } from '@vkontakte/vkui';
import './index.scss';

import wordPad from '../../../utils/wordPad';
import shareStory from '../../../utils/shareStory';

const CalculatorResult = ({ id, changePanel }) => {
    const result = useSelector((state) => state.calculator.result);
    const years = useSelector((state) => state.calculator.years);
    const fact = useSelector((state) => state.calculator.facts[String(state.calculator.selected)] || "");
    const [finallyImage, setFinallyImage] = useState(null);

    let facts = fact.split('\n');
    const fact_line_height = 100;

    facts = facts.reverse().map((fact, index) => ({
        x: 540,
        y: (960 + Math.floor(facts.length * fact_line_height / 2)) - (index * fact_line_height),
        value: fact,
        font: "58px Arial",
        align: "center",
        color: "#FFFFFF"
    }));

    VKStories.generateStoryFromTemplate(result.image, [
        {
            x: 540,
            y: 250,
            value: "Я болею за сборную",
            font: "82px Arial",
            align: "center",
            color: "#FFFFFF"
        },
        {
            x: 540,
            y: 450,
            value: years === 0 ? "Меньше года" : `${years} ${wordPad(years, "год", "года", "лет")}`,
            font: "128px Arial",
            align: "center",
            color: "#FFFFFF"
        },
        ...facts
    ])
        .then((image) => setFinallyImage(image));

    return (
        <Panel id={id}>
            <PanelHeader
                left={<PanelHeaderBack onClick={() => changePanel("calculator-intro")} />}
            >
                Результат
            </PanelHeader>
            <Div className="calculator-result">
                <div
                    className="calculator-result__cover"
                    style={{
                        backgroundImage: `url(${finallyImage})`
                    }}
                />
            </Div>
            <FixedLayout vertical="bottom">
                <Div>
                    <Button size="xl" onClick={() => shareStory(finallyImage)}>
                        Поделиться
                    </Button>
                </Div>
            </FixedLayout>
        </Panel>
    );
};

export default CalculatorResult;
