import * as types from './actionTypes';

const initialState = {
    loaded: false,
    covers: [],
    period: [0, 0],
    result: null,
    facts: {},
    years: 0,
    selected: 0,
};

const calculate = (covers = [], year) => {
    for (let i = 0; i < covers.length; i++) {
        if (year <= covers[i].year) {
            return covers[i];
        }
    }

    return covers[covers.length - 1];
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.CALCULATOR_LOAD: {
            const { covers, period, facts } = action;
            return {
                ...state,
                loaded: true,
                covers,
                period,
                facts,
            };
        }

        case types.CALCULATOR_RESULT: {
            const { year } = action;
            return {
                ...state,
                result: calculate(state.covers, year),
                years: 2020 - year,
                selected: year,
            };
        }

        default: return state;
    }
};
