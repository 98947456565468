import 'core-js/features/map';
import 'core-js/features/set';

import React from 'react';
import ReactDOM from 'react-dom';
import connect from '@vkontakte/vk-connect';

// App
import App from './App';
import '@vkontakte/vkui/dist/vkui.css';

// Store
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './store';

import * as API from './api';
import { loadCourses } from './store/courses/actions';
import { loadQuizes } from './store/quizes/actions';
import { loadCalculator } from './store/calculator/actions';
import { loadVote } from './store/vote/actions';

export const store = createStore(rootReducer);
API.getCourses().then((courses) => store.dispatch(loadCourses(courses)));
API.getQuizes().then((quizes) => store.dispatch(loadQuizes(quizes)));
API.getCalculator().then((result) => store.dispatch(loadCalculator(result.covers, result.period, result.facts)));
API.getVote().then((result) => store.dispatch(loadVote(result)));

// Theme changer
connect.subscribe(({ detail: { type, data }}) => {
    if (type === "VKWebAppUpdateConfig") {
        const schemeAttribute = document.createAttribute("scheme");
        schemeAttribute.value = data.scheme ? data.scheme : "bright_light";
        document.body.attributes.setNamedItem(schemeAttribute);
    }
});

// Init VK Mini App
connect.send('VKWebAppInit');

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
