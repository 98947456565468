import * as types from './actionTypes';

const initialState = {
    loaded: false,
    link: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.VOTE_LOAD: {
            const { link } = action;
            return {
                loaded: true,
                link,
            };
        }

        default: return state;
    }
};
