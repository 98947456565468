import * as types from './actionTypes';

const initialState = {
    loaded: false,
    list: [],
    selected: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.COURSES_LOAD: {
            const { courses } = action;
            return {
                ...state,
                list: courses,
                loaded: true,
            };
        }

        case types.COURSES_OPEN: {
            const { id } = action;
            return {
                ...state,
                selected: state.list.find((x) => x.id === id),
            };
        }

        default: return state;
    }
};
