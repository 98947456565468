import React, { useState, useCallback } from 'react';
import VKStories from 'vk-stories';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, PanelHeader, PanelHeaderBack, Button, Div, FixedLayout } from '@vkontakte/vkui';
import './index.scss';

import Icon56CheckCircleOutline from '@vkontakte/icons/dist/56/check_circle_outline';

import { openQuiz } from '../../../store/quizes/actions';
import shareStory from '../../../utils/shareStory';

import Icon24Story from '@vkontakte/icons/dist/24/story';
import Icon24Replay from '@vkontakte/icons/dist/24/replay';
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';

const QuizFinal = ({ id, changePanel }) => {
    const dispatch = useDispatch();
    const onClose = () => changePanel("quizes");

    const quiz_id = useSelector((state) => state.quizes.selected.id);
    const result = useSelector((state) => state.quizes.result);
    const restart = useCallback(() => dispatch(openQuiz(quiz_id)), [dispatch, quiz_id]);
    const [story, setStory] = useState(null);

    if (result && result.image) {
        VKStories.generateStoryFromTemplate(result.image, [
            {
                x: 540,
                y: 860,
                value: `${result.points} из ${result.total}`,
                font: "82px Arial",
                align: "center",
                color: "#FFFFFF"
            },
            {
                x: 540,
                y: 990,
                value: result.text,
                font: "82px Arial",
                align: "center",
                color: "#FFFFFF"
            },
            {
                x: 540,
                y: 1090,
                value: result.description || "",
                font: "46px Arial",
                align: "center",
                color: "#FFFFFF"
            }
        ])
            .then((image) => setStory(image));
    }

    // let text = `Вы набрали ${result && result.points} из ${result && result.total} баллов`;

    return (
        <Panel id={id} theme="white">
            <PanelHeader
                left={<PanelHeaderBack onClick={onClose} />}
            >
                Результат
            </PanelHeader>
            <Div>
                <Icon56CheckCircleOutline
                    fill="var(--accent)"
                    width="96"
                    height="96"
                    className="quiz-final__icon"
                />
                <div className="quiz-final__text">
                    <div className="quiz-final__title">{result && result.text}</div>
                    <div className="quiz-final__subtitle">{result && (result.description || "Викторина завершена")}</div>
                </div>
            </Div>
            <FixedLayout vertical="bottom">
                <Div>
                    <Button
                        stretched
                        size="xl"
                        before={<Icon24Story/>}
                        disabled={story === null}
                        style={{ marginBottom: 8 }}
                        onClick={() => shareStory(story)}
                    >
                        Поделиться
                    </Button>
                    <Div style={{ display: 'flex', padding: 0 }}>
                        <Button
                            stretched
                            size="xl"
                            mode="secondary"
                            before={<Icon24Replay/>}
                            onClick={() => {
                                restart();
                                changePanel("quiz-task");
                            }}
                            style={{ marginRight: 8 }}
                        >
                            Повторить
                        </Button>
                        <Button
                            stretched
                            size="xl"
                            mode="secondary"
                            before={<Icon24Cancel/>}
                            onClick={onClose}
                        >
                            Закрыть
                        </Button>
                    </Div>
                </Div>
            </FixedLayout>
        </Panel>
    );
};

export default QuizFinal;
