import * as types from './actionTypes';

const initialState = {
    view: 'home',
    panel: 'home'
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.NAVIGATOR_OPEN_PANEL: {
            const { panel } = action;
            return {
                ...state,
                panel
            };
        }

        case types.NAVIGATOR_OPEN_VIEW: {
            const { view, panel } = action;
            return {
                ...state,
                view,
                panel,
            };
        }

        default: return state;
    }
};
