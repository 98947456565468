import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const SectionCard = ({ name, icon, description, color, onClick }) => {
    return (
        <div className="section-card" onClick={onClick} style={{ backgroundColor: color }}>
            <div className="section-card__wrapper">
                <div className="section-card__icon">
                    <img src={icon} alt="" />
                </div>
                <div className="section-card__info">
                    <div className={`section-card__name ${!description ? "section-card__name-solo" : ""}`}>{name}</div>
                    <div className="section-card__description"/>
                </div>
            </div>
        </div>
    );
};

SectionCard.propTypes = {
    icon: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string,
};

export default SectionCard;
