import { combineReducers } from 'redux';

import navigator from './navigator';
import courses from './courses';
import quizes from './quizes';
import calculator from './calculator';
import vote from './vote';

export default combineReducers({
    navigator,
    courses,
    quizes,
    calculator,
    vote,
});
