import VKConnect from "@vkontakte/vk-connect";

const shareStory = (blob) => {
    VKConnect.send("VKWebAppShowStoryBox", {
        "background_type" : "image",
        "blob" : blob,
        "locked": true,
        "attachment": {
            "text": "open",
            "type": "url",
            "url": "https://vk.com/app7304797"
        }
    });
};

export default shareStory;
