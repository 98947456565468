import * as types from './actionTypes';

export const loadCourses = (courses = []) => {
    return {
        type: types.COURSES_LOAD,
        courses
    };
};

export const openCourse = (course_id = null) => {
    return {
        type: types.COURSES_OPEN,
        id: course_id,
    };
};
